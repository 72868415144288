import {
  createBackendApiClient,
  createBackendApiClientWithReq,
} from "./createBackendApiClient";
import { ProfileData, ServerRequest } from "./../../types";
import { getAuthStatus } from "../getAuthStatus";
import { Person, Profile } from "./generated";
import { formatDate } from "@/utils/dateUtils";

export interface UploadedFile {
  name: string;
  displayName: string;
  documentTypeName: string;
  createdDate?: string;
  relativeFileUrl: string;
}

export class UploadedFileHelper {
  static formatDisplayName(file: UploadedFile): string {
    if (file.createdDate === undefined) {
      return file.displayName;
    }
    return `${file.displayName} (created ${file.createdDate})`;
  }
}

export const getUserProfile = async (
  req: ServerRequest
): Promise<ProfileData | null> => {
  const auth = getAuthStatus(req);

  if (!auth.isAuthenticated) {
    return null;
  }

  const client = createBackendApiClient(auth.token);
  const backendProfile = await client.profile_Get();
  return mapProfile(backendProfile);
};

export const getPersonByAzureId = async (
  id: string,
  req: ServerRequest
): Promise<Person | null> => {
  const auth = getAuthStatus(req);

  if (!auth.isAuthenticated) {
    return null;
  }

  if(id === "") {
    return null;
  }

  const client = createBackendApiClient(auth.token);
  const person = await client.person_GetByAzureAdId(id);
  return person;
};


export const getUserFiles = async (
  req: ServerRequest
): Promise<UploadedFile[] | undefined> => {
  const auth = getAuthStatus(req);
  if (!auth.isAuthenticated) return undefined;

  const client = createBackendApiClient(auth.token);
  const files = await client.profile_GetFiles();
  return files.map((file) => ({
    name: file.name,
    documentTypeName: file.documentTypeName,
    displayName: file.displayName,
    createdDate: formatDate("long", file.createdDate, true),
    // URL to nextjs api page for downloading profile file.
    relativeFileUrl: `/api/profile/file/download/${encodeURIComponent(
      file.name
    )}`,
  }));
};

export async function getCompetencePassportOrNull(req: ServerRequest) {
  const auth = getAuthStatus(req);
  if (!auth.isAuthenticated) {
    return null;
  }
  const client = createBackendApiClientWithReq(req);
  return await client.profile_GetCompetencePassport();
}

const mapProfile = (backendProfile: Profile): ProfileData => {
  return {
    id: backendProfile.azureAdUserDetails.id,
    username: backendProfile.azureAdUserDetails.userName,
    firstName: backendProfile.azureAdUserDetails.givenName,
    displayName: `${backendProfile.azureAdUserDetails.givenName} ${backendProfile.azureAdUserDetails.surName}`,
    recoveryEmailAddress: backendProfile.recoveryEmailAddress,
    doNotBulkEmails: backendProfile.doNotBulkEmails,
    homeOrganisationId:backendProfile.homeOrganisationId,
    tags: backendProfile.tags,
    personalInformation: backendProfile.personalInformation,
    mobilePhone: backendProfile.mobilePhone,
    lastName: backendProfile.azureAdUserDetails.surName,
    eciuEmailAddress: backendProfile.eciuEmailAddress,
    recoveryEmailVerifiedDate: backendProfile.recoveryEmailVerifiedDate,
    isCorporateTrainingManager: backendProfile.isCorporateTrainingManager,
  };
};
